// ableton.com Library imports go here

// Loop-specific Library imports go here

// ableton.com Module imports go here

// Loop-specific Module imports go here
import ClickRemote from './loop/click-remote/click-remote';
import LoadMoreCta from './loop/abl-load-more-cta/abl-load-more-cta';

// ableton.com Web Components go here

// Loop-specific Web Components go here
customElements.define('abl-click-remote', ClickRemote);
customElements.define('abl-load-more-cta', LoadMoreCta);
