export default class ClickRemote extends HTMLElement {
  constructor() {
    super();

    this.targetEl = document.getElementById(this.dataset.target);
    this.classList.add('abl-cursor-pointer');
    this.addEventListener('click', () => {
      this.targetEl.click();
    });
  }
}
