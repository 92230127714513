import {
  activateTemplates,
  removeFallbackElements
} from '../../abl-lib/abl-lib';

export default class LoadMoreCta extends HTMLElement {
  constructor() {
    super();
    // If JavaScript works, we render the button from the template element
    activateTemplates(this);

    this.replaceSelector = '.js-load-more-cta-replace';

    this.ctaLink = this.querySelector('.js-load-more-cta-link');
    this.href = this.ctaLink.getAttribute('href');
    this.targetELement = document.getElementById(this.dataset.target);

    this.ctaButton = this.querySelector('.js-load-more-cta-button');
    this.ctaButton.addEventListener('click', () => {
      this.handleButtonClick();
    });

    this.ctaTextLoaded = this.querySelector('.js-load-more-cta-text-initial');
    this.ctaTextLoading = this.querySelector('.js-load-more-cta-text-loading');

    // Set default state
    this.loading = false;

    // After our component is ready, we remove the fallback CTA link
    removeFallbackElements(this);
  }

  set loading(loading) {
    // If loading state didn’t change, do nothing
    if (loading === this.loading) return;

    if (loading) {
      // If we’re entering loading state, show loading text and disable CTA button
      this.ctaTextLoading.classList.remove('abl-hidden');
      this.ctaTextLoaded.classList.add('abl-hidden');

      this.ctaButton.disabled = true;
    } else {
      // If we’re leaving loading state, show loaded text and enable CTA button
      this.ctaTextLoading.classList.add('abl-hidden');
      this.ctaTextLoaded.classList.remove('abl-hidden');

      this.ctaButton.disabled = false;
    }
  }

  appendEntries(sourceDocument, targetDocument, targetId) {
    const sourceElement = sourceDocument.getElementById(targetId);
    const targetElement = targetDocument.getElementById(targetId);
    if (targetElement) {
      targetElement.insertAdjacentHTML('beforeend', sourceElement.innerHTML);
    }
  }

  replaceElements(sourceDocument, targetDocument, selector) {
    const sourceElements = sourceDocument.querySelectorAll(selector);
    sourceElements.forEach(sourceElement => {
      const targetElement = targetDocument.getElementById(sourceElement.id);
      if (targetElement) {
        targetElement.replaceWith(sourceElement);
      }
    });
  }

  handleButtonClick() {
    this.loading = true;
    const lastEntryBeforeLoad = this.targetELement?.lastChild;

    // Fetch next page from this.href
    fetch(this.href)
      .then(response => response.text())
      .then(html => {
        // Parse next page response as new HTML document
        // From https://gomakethings.com/getting-html-with-fetch-in-vanilla-js/
        const parser = new DOMParser();
        const loadedDocument = parser.parseFromString(html, 'text/html');

        this.appendEntries(loadedDocument, document, this.dataset.target);

        this.replaceElements(loadedDocument, document, this.replaceSelector);

        if (this.targetELement) {
          const firstNewEntry = lastEntryBeforeLoad.nextSibling;
          // Make sure firstNewEntry has a tabindex so it can receive focus
          if (firstNewEntry.getAttribute('tabindex') === null) {
            firstNewEntry.setAttribute('tabindex', '-1');
          }
          // After loading, give focus to first newly loaded entry
          // See https://www.accede-web.com/en/guidelines/rich-interface-components/show-more-buttons/
          firstNewEntry.focus();
        }

        // After we’re done, set loading state to false
        this.loading = false;
      })
      .catch(error => {
        console.error(error);

        // If there is an error, set loading state to false
        // so button can be pressed to try again
        this.loading = false;
      });
  }
}
