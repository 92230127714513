export default function activateTemplates(
  baseElement,
  templateSelector = 'template'
) {
  // Replaces every template element with its respective contents
  const templates = baseElement.querySelectorAll(templateSelector);
  return templates.forEach(template => {
    const temporaryWrapper = document.createElement('div');
    temporaryWrapper.innerHTML = template.innerHTML;
    template.replaceWith(...[...temporaryWrapper.childNodes]);
  });
}
